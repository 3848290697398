var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "logout", staticClass: "container" },
    [
      _c("div", [_vm._v("您已经安全退出，请手动关闭浏览器；或者")]),
      _c("el-button", { attrs: { type: "text" }, on: { click: _vm.reLogin } }, [
        _vm._v("重新登录")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }