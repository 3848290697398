var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _vm.showLoginPage
      ? _c(
          "div",
          { ref: "login", staticClass: "login-container" },
          [
            _c("top-color", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ]
            }),
            _c("div", { staticClass: "login-weeper animated bounceInDown" }, [
              _c("div", { staticClass: "login-left" }, [
                _c("div", { staticClass: "login-time" }, [
                  _vm._v("\n          " + _vm._s(_vm.time) + "\n        ")
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("login.info")))
                ])
              ]),
              _c("div", { staticClass: "login-border" }, [
                _c(
                  "div",
                  { staticClass: "login-main" },
                  [
                    _c(
                      "h4",
                      { staticClass: "login-title" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("login.title")) +
                            _vm._s(_vm.website.title) +
                            "\n            "
                        ),
                        _c("top-lang")
                      ],
                      1
                    ),
                    _vm.activeName === "user" ? _c("userLogin") : _vm._e(),
                    _c("div", { staticClass: "login-menu" })
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      : _vm._e(),
    _vm.showUnAuth
      ? _c("div", { staticClass: "showUnAuth" }, [
          _c("div", [_vm._v("抱歉您没有权限登录，请联系管理员")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }