<template>
  <div class="container"
       ref="logout"
  >
    <div>您已经安全退出，请手动关闭浏览器；或者</div>
    <el-button type="text" @click="reLogin">重新登录</el-button>
  </div>
</template>
<script>


export default {
  name: "logout",
  created() {
  },
  props: [],
  methods: {
    reLogin(){
      this.$router.replace("/login")
    }
  }
};
</script>
<style lang="scss" scoped>
html, body{
  width: 100%;
  height: 100%;
}
.container{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>

