<template>
  <div class="login-container">
    <div class="login-container"
         v-if="showLoginPage"
         ref="login"
    >
      <top-color v-show="false"></top-color>
      <div class="login-weeper animated bounceInDown">
        <div class="login-left">
          <div class="login-time">
            {{ time }}
          </div>
          <p class="title">{{ $t('login.info') }}</p>
        </div>
        <div class="login-border">
          <div class="login-main">
            <h4 class="login-title">
              {{ $t('login.title') }}{{ website.title }}
              <top-lang></top-lang>
            </h4>
            <userLogin v-if="activeName==='user'"></userLogin>
            <div class="login-menu">
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="showUnAuth" v-if="showUnAuth">
      <div>抱歉您没有权限登录，请联系管理员</div>
    </div>
  </div>
</template>
<script>
import userLogin from "./userlogin";
import codeLogin from "./codelogin";
import thirdLogin from "./thirdlogin";
import {mapGetters} from "vuex";
import {dateFormat} from "@/util/date";
import topLang from "@/page/index/top/top-lang";
import topColor from "@/page/index/top/top-color";
import config from "@/config/const";
import {getItem} from "@/util/storage";

export default {
  name: "login",
  components: {
    userLogin,
    codeLogin,
    thirdLogin,
    topLang,
    topColor
  },
  data() {
    return {
      showLoginPage: false,
      showUnAuth: false,
      time: "",
      activeName: "user",
      socialForm: {
        tenantId: "222222",
        source: "",
        code: "",
        state: "",
      }
    };
  },

  created() {
    this.getTime();
    this.handleLoginByGeely()
  },
  computed: {
    ...mapGetters(["tagWel"])
  },
  props: [],
  methods: {
    getTime() {
      setInterval(() => {
        this.time = dateFormat(new Date());
      }, 1000);
    },
    // 单点登录
    handleLoginByGeely() {
      let ticket = this.$route.query.ticket
      const href = window.location.href
      const host = window.location.host
      const protocol = window.location.protocol
      let isGeely = (href.indexOf('geely.com') !== -1)
      if (isGeely) {
        if (ticket) {
          const loading = this.$loading({
            lock: true,
            text: '登录中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$store.dispatch('LoginByGeely', ticket).then(res => {
            loading.close()
            if (res.code === 200) {
              this.$router.push({path: this.tagWel.value});
            }
          }).catch(() => {
            loading.close()
            this.showUnAuth = true
            this.showLoginPage = false
          })
        } else {
          if (!getItem('TOKEN_KEY')) {
            window.location.href = `${process.env["VUE_APP_LOGIN_URL"]}/?appKey=${process.env["VUE_APP_APP_KEY"]}&redirectUrl=${protocol}//${host}/login`
          }
        }
      } else if (ticket) {
        const loading = this.$loading({
          lock: true,
          text: '登录中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$store.dispatch('LoginByGeely', ticket).then(res => {
          if (res.code === 200) {
            this.$router.push({path: this.tagWel.value});
          }
        }).catch(() => {
          loading.close()
          this.showUnAuth = true
          this.showLoginPage = false
        })
      } else {
        this.showLoginPage = true
      }
    },

  }
};
</script>

<style lang="scss">
@import "@/styles/login.scss";
.showUnAuth{
  display: flex;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
}
</style>
